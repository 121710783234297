export function getInternalLinkHref(
  internalLink?: {
    type?: string;
    slug?: string;
  },
  suffix?: string
) {
  let href = '';

  if (!internalLink?.type) {
    console.warn('dont know what to do with this reference');
    return href;
  }

  if (internalLink.type === 'project' && internalLink?.slug) {
    href = `/project/${internalLink.slug}${suffix}`;
  } else if (
    internalLink.type === 'work' ||
    internalLink.type === 'reference'
  ) {
    href = `/work${suffix}`;
  } else if (internalLink.type === 'toolkit' && internalLink.slug) {
    href = `/toolkit/${internalLink.slug}${suffix}`;
  } else if (internalLink.type === 'page') {
    href = `/${internalLink.slug}${suffix}`;
  } else {
  }
  return href;
}
