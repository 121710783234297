import { Box } from '@/components/box';
import { motion } from 'framer-motion';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useIntersection } from 'react-use';

type asType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span' | 'div';

const longCopyThreshold = 10;
const forceUpdateKey = 0;
const longCopy = {
  duration: 0.55,
  delay: 0.03,
  colorStart: 1,
  curve: [0.4, 0, 0.2, 1],
};

const shortCopy = {
  duration: 0.8,
  delay: 0.1,
  colorStart: 0.22,
  curve: [0.46, 0.03, 0.52, 0.96],
};

interface StaggeredTextProps {
  text?: string | string[];
  as?: asType;
  startColor?: string;
  endColor?: string;
  enterDelayInSeconds?: number;
  disableAnimation?: boolean;
}

export function StaggeredText({
  text,
  as = 'span',
  startColor,
  endColor,
  enterDelayInSeconds = 0,
  disableAnimation,
  ...props
}: StaggeredTextProps) {
  const [isAnimating, setIsAnimating] = useState(false);
  const intersectionRef = useRef(null);
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
  });

  useEffect(() => {
    if (intersection?.isIntersecting && !isAnimating) {
      setIsAnimating(true);
      intersectionRef.current = null;
    }
  }, [intersection?.isIntersecting, isAnimating]);

  if (Array.isArray(text)) {
    text = text[0];
  }

  if (!text) {
    return null;
  }

  if (typeof text !== 'string') {
    console.warn(
      'Invalid text prop passed to <StaggeredText />. It needs to be typeof "string"'
    );

    return null;
  }

  /**
   * Because we want to support newline characters coming from the CMS,
   * we need to define multiple arrays
   * of words consisting of rows.
   * Save the original index number so we can animate it
   */

  let loopIndex = 0;

  const rows = text.split('\n').map((row) => {
    return (
      row.split(/\s+/).map((word) => {
        const obj = {
          index: loopIndex,
          value: word,
        };
        loopIndex++;
        return obj;
      }) || []
    );
  });

  const flattenedLength = rows.flat().length;

  return (
    <Box ref={intersectionRef} as={as} {...props}>
      {rows.map((row, rowIndex) => {
        return (
          <Box
            key={`row-${rowIndex}-${forceUpdateKey}`}
            css={{
              display: 'inline-flex',
              flexWrap: 'wrap',
              columnGap: '0.25em',
            }}
          >
            {row.map((word) => {
              return (
                <Word
                  key={`word-${word.index}-${forceUpdateKey}`}
                  index={word.index}
                  animate={isAnimating}
                  isLongCopy={flattenedLength > longCopyThreshold}
                  startColor={startColor}
                  endColor={endColor}
                  enterDelayInSeconds={enterDelayInSeconds}
                  disableAnimation={disableAnimation}
                >
                  {word.value}
                </Word>
              );
            })}
          </Box>
        );
      })}
    </Box>
  );
}

interface WordProps {
  children: React.ReactNode;
  index: number;
  animate?: boolean;
  isLongCopy: boolean;
  startColor?: string;
  endColor?: string;
  enterDelayInSeconds?: number;
  disableAnimation?: boolean;
}

function Word({
  children,
  index,
  animate,
  isLongCopy,
  startColor,
  endColor,
  enterDelayInSeconds = 0,
  disableAnimation,
}: WordProps) {
  const activeAnimationProperties = isLongCopy ? longCopy : shortCopy;

  const variants = useMemo(
    () => ({
      hidden: {
        opacity: 0,
        y: '100%',
        color: startColor ? startColor : '$foreground',
      },
      visible: {
        opacity: 1,
        color: endColor ? endColor : '$foreground',
        y: 0,
        transition: {
          duration: disableAnimation ? 0 : activeAnimationProperties.duration,
          delay: disableAnimation
            ? 0
            : enterDelayInSeconds + index * activeAnimationProperties.delay,
          ease: [
            activeAnimationProperties.curve[0],
            activeAnimationProperties.curve[1],
            activeAnimationProperties.curve[2],
            activeAnimationProperties.curve[3],
          ],
        },
      },
    }),
    [
      activeAnimationProperties.curve,
      activeAnimationProperties.delay,
      activeAnimationProperties.duration,
      disableAnimation,
      endColor,
      enterDelayInSeconds,
      index,
      startColor,
    ]
  );

  return (
    <Box
      key={`key-${index}`} // dirty but it's only for testing
      as="span"
      css={{
        overflow: 'hidden',
        display: 'inline-flex',
        lineHeight: 1.25,
      }}
    >
      <motion.span
        initial="hidden"
        animate={animate ? 'visible' : 'hidden'}
        variants={variants}
        style={{ display: 'inline-flex' }}
        exit={{
          opacity: 0,
          y: '100%',
          color: startColor ? startColor : '$foreground',
          transition: {
            duration: disableAnimation
              ? 0
              : activeAnimationProperties.duration * 0.75,
            delay: disableAnimation
              ? 0
              : enterDelayInSeconds +
                index * (activeAnimationProperties.delay * 0.75),
            ease: [
              activeAnimationProperties.curve[0],
              activeAnimationProperties.curve[1],
              activeAnimationProperties.curve[2],
              activeAnimationProperties.curve[3],
            ],
          },
        }}
      >
        {children}
      </motion.span>
    </Box>
  );
}
